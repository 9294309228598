<template>
    <ErrorContainer :title="title">
        <template v-slot:default>
            <media-only desktop>
                <SearchBar v-if="isSearchVisible" />
            </media-only>
        </template>

        <template v-slot:text>
            <p class="mb-0">
                {{ $t('404.text') }}
            </p>
        </template>
    </ErrorContainer>
</template>

<script>
import getHead from '@/mixins/getHead';
import ErrorContainer from '@/components/error/ErrorContainer';

export default {
    name: 'NotFound',
    layout: ({ layout }) => layout,
    mixins: [getHead],
    async fetch(context) {
        //let path = context.query.from || context.route.redirectedFrom;
        let path = context.query.from; // only having "from" query param makes sure redirect occurred for route, not an asset

        if (path) {
            try {
                if (path.startsWith('/')) {
                    path = path.substr(1);
                }
                const { data } = await context.$axios.get(`/shop/redirect?path=${path}`);
                if (data.location) {
                    context.redirect(location);
                }
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    //do nothing
                }
            }
        }
    },
    head() {
        return this.getHead({
            pageTitle: this.title.concat(' | Animod')
        });
    },
    components: {
        ErrorContainer,
        SearchBar: () => import(/* webpackChunkName: "search-bar" */ '@/components/search/SearchBar')
    },
    computed: {
        title() {
            return this.$t('404.header');
        },
        isSearchVisible() {
            return this.$store.state.globalMeta.searchBarVisible;
        }
    },
    methods: {
        sendGTMEvent() {
            this.$gtm && this.$gtm.pushPageView('error404');
        }
    },
    beforeMount() {
        this.sendGTMEvent();
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins';

#notFound {
    .main {
        background-color: var(--body-bg);
    }

    .title {
        font-size: 20px;
        font-weight: 700;

        @include mixins.mobile-only {
            text-transform: uppercase;
        }
    }

    .btn-info {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        padding: 18px;
        line-height: 1em;
    }
}
</style>
